import React from 'react';
import Slider from '../slider/slider';
import Testimonial from '../testimonial/testimonial';

const testimonials = [
  {
    "rating": 4,
    "reviewText": "Switching to [Associations Platform Name] made a big difference for us. It's easy to use, and the communication tools helped us get 30% more people involved.Collaborative workspaces and document sharing have transformed how we operate. Thanks, [Associations Platform Name] team, for making our association stronger!",
    "reviewerName": "Lorem Ipsum",
    "reviewerCompany": "Established Dact that a reader"
  },
  {
    "rating": 5,
    "reviewText": "Thanks to [Associations Platform Name]'s voting features, conducting elections is a breeze! Setting up the vote was simple, and the platform's user-friendly interface made participation easy for everyone. The quick results and smooth process have taken the hassle out of elections, allowing us to focus on our mission. [Associations Platform Name] has truly simplified the way we make decisions together – it's a game-changer for our association!",
    "reviewerName": "Lorem Ipsum",
    "reviewerCompany": "Established Dact that a reader"
  },
  {
    "rating": 4,
    "reviewText": "Attending conferences and events worldwide has never been more accessible before [Associations Platform Name]. The virtual capabilities allowing us to participate from anywhere has broken down geographical barriers. This all-in-one platform not only simplifies event management but also lets us seamlessly handle 2-3 associations in a single place. It's a game-changer, providing flexibility and efficiency for our global collaboration. Thanks to [Associations Platform Name], our events are more inclusive.",
    "reviewerName": "Lorem Ipsum",
    "reviewerCompany": "Established Dact that a reader"
  },

  
];

function TestimonialSlider() {
  return (
    <div className='testimonial-slider-container'>
        <Slider>
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index} {...testimonial} />
          ))}
        </Slider>
    </div>
  );
}

export default TestimonialSlider;
