import React from "react";
import { Helmet } from "react-helmet";
import './term.css';
import { useState } from "react";
function TermsAndConditions() {
  const [showContent, setshowContent] = useState(new Array(21).fill(false));
 
   // Function to toggle content visibility for a specific section
   const toggleSection = (index) => {
    setshowContent((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <div className="container tnC-page">
      <Helmet>
        <title>Terms and Conditions</title>
        <meta
          name="description"
          content="Our Terms and Conditions outline the guidelines for using our healthcare services. Please review them carefully before using our platform."
        />
      </Helmet>

      <div className="p-6 bg-gray-100 rounded-lg">
        <div className="home-hero-section term-page">
          <div className="container lg:flex head-home-sec gap-extra">
            <div className="lg:w-6/12  head-home-sec-centre">
              <h3 className="heading">
                {" "}
                <b>Terms and Conditions</b>
              </h3>
            </div>
            <div className="lg:w-6/12 head-home-sec-centre"></div>
          </div>
        </div>



        <div className="pvcpolcy">
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(0)}>
            1. Overview
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[0] && (
            <div class="toggle-content">
              <p className="mb-10">
                Tera Connects manages this website. Tera Connects is referred to as
                "we", "us", and "our" throughout the site. Tera Connects Platform
                provides you, the user, with this website, including all information
                and services available through this site/platform, conditioned on
                your acceptance of all terms, conditions, rules, and notices
                indicated here.{" "}
              </p>
              <p>
                By accessing our site and/or using our services, you participate in
                our "Service" and agree to be governed by the terms and conditions
                set out below ("Terms of Service", "Terms"), including such
                additional terms and conditions and policies indicated below. These
                Terms of Service apply to all users of the site/platform, including
                without limitation browsers, vendors, users, and/or content
                creators.{" "}
              </p>
              <p>
                Please read these Terms of Service carefully before accessing or
                using our website/platform. By accessing or using any part of the
                site, you agree to be bound by these Terms of Service. If you do not
                agree to all the terms and conditions of this agreement, then you
                may not access the website(“platform”) or use any services. If these
                Terms of Service are considered an offer, acceptance is expressly
                limited to these Terms of Service.{" "}
              </p>
              <p>
                Any new features or tools which are added to the current
                platform(“website”) shall also be subject to the Terms of Service.
                You can review the most current version of the Terms of Service at
                any time on this page. We reserve the right to update, change or
                replace any part of these Terms of Service by posting updates and/or
                changes to our website. It is your responsibility to check this page
                periodically for changes. Your continued use of or access to the
                website following the posting of any changes constitutes acceptance
                of those changes.{" "}
              </p>
            </div>
          )}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(1)}>
            2. Online Terms/Eligibility
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[1] && (
            <div class="toggle-content">
          <p className="mb-10">
            By agreeing to these Terms of Service, you signify that you are at
            least the legal age of majority in your state or province of
            residence, or that you are the legal age of majority in your state
            or province of residence and have granted us permission to enable
            any of your minor dependents to use this site.{" "}
          </p>
          <p>
            You may not use our goods for any unlawful or unauthorized purpose,
            and you may not break any laws in your jurisdiction (including, but
            not limited to, copyright laws) by using the Service.{" "}
          </p>
          <p>
            When using our services, you are not authorized to use foul
            language.Any violation of the Terms will result in the immediate
            termination of your Services.{" "}
          </p>
          </div>
          )}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(2)}>
            {" "}
            3. Obligations for User SignUp
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[2] && (
            <div class="toggle-content">
            <p className="mb-10">
            If a user creates an account on our platform to access our
            services.You promise to: (i) give true, accurate, current, and
            complete information about yourself as prompted by the sign up
            process; and (ii) maintain and promptly update the information
            provided during sign up to ensure that it is true, accurate,
            current, and complete. Tera may cancel your user account and reject
            current or future use of any or all of the Services if you supply
            any information that is false, inaccurate, outdated, or incomplete,
            or if Tera has reasonable grounds to think that such information is
            false, inaccurate, outdated, or incomplete.{" "}
          </p>
          <p>
            Users are responsible for maintaining the confidentiality of their
            account information, including their login credentials. This means
            they should not share their username or password with anyone else,
            and should take steps to protect their account from unauthorized
            access.{" "}
          </p>
          <p>
            We have the right to terminate or suspend user accounts if users
            violate the terms and conditions of the platform. This may include
            activities such as attempting to access another user's account,
            engaging in fraudulent activities, or posting inappropriate content.
            Users should carefully review the terms and conditions of the
            platform and comply with them to avoid the risk of having their
            account terminated or suspended.{" "}
          </p>
          </div>
          )}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(3)}>
            4. Acceptable Use policy 
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[3] && (
            <div class="toggle-content">
          <p className="mb-10">
            You are solely responsible for all of your Tera Connects-related
            activities. Because of the worldwide nature of the internet, by
            using our network, you agree to abide by all local regulations
            pertaining to online conduct and permissible Content. Make certain
            that you utilize Tera Connects in accordance with the law. If your
            use of Tera Connects is forbidden by relevant laws, you are not
            permitted to use the platform. We cannot and will not be held liable
            if you use the Tera Connects platform in a way that violates the
            law.{" "}
          </p>
          <p>
            You also promise not to utilize Tera Connects' platform in any way
            that
          </p>
          <ul className="circle-list">
            <li>Is deceptive.</li>
            <li>
              jeopardizes the security of your Tera Connects account or the
              security of anybody else's (for example, allowing someone else to
              log into Tera Connects as you, or sharing your account or password
              with someone);
            </li>
            <li>
              Attempts in any way to gain another user's password, account, or
              other security information;{" "}
            </li>
            <li>
              Decompiles, reverse engineers, or otherwise seeks to access Tera
              Connects source code or underlying concepts or knowledge.
            </li>
            <li>
              Replicates, duplicates, copies, trades, sells, resells, or
              exploits any component, use, or access to Tera Connects for any
              commercial reason.{" "}
            </li>
          </ul>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(4)}>
            5. Service modifications and Price changes
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[4] && (
            <div class="toggle-content">
          <p className="mb-10">
            We reserve the right to modify or discontinue the Service (or any
            part of it) at any time without prior notice.{" "}
          </p>
          <p>
            We may, at our sole discretion, change the prices of the Service
            from time to time without prior notice.{" "}
          </p>
          <p>
            We shall not be liable to you or to any third-party for any
            modification, price change, suspension or discontinuance of the
            Service.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(5)}>
            6. Job Services and recruitment Services
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[5] && (
            <div class="toggle-content">
          <p className="mb-10">
            Employers may post jobs through our platform. The Company does not
            guarantee the accuracy or completeness of job advertisements, and we
            are not responsible for any errors or omissions in the content of
            job posts.{" "}
          </p>
          <p>
            Candidates may apply for job opportunities through our platform. We
            do not guarantee that a candidate will be selected for an interview
            or offered a job. We are not responsible for any decisions made by
            the Employer regarding the selection of candidates.{" "}
          </p>
          <p>
            Tera enables employers to contact employees and check their
            profiles, which include information about the employee's experience.
            We do not provide experience certificates to registered members.
          </p>
          <p>
            Tera will advise the employer to contact the employee within the
            time frame specified; we will not be held liable.
          </p>
          <p>
            We are not responsible if any candidate has committed crime/illegal
            activity at the employer's premises. Background verification of
            candidates who are/will be hired is a responsibility of the
            respective recruiter/recruiter's organization.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(6)}>
            7. Accuracy of account information
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[6] && (
            <div class="toggle-content">
          <p className="mb-10">
            You are responsible for ensuring the accuracy of your account
            information provided to us. We reserve the right to verify and
            validate the information provided by you and may take appropriate
            action in case of any discrepancies.{" "}
          </p>
          <p>
            By using our services, you agree to provide accurate and up-to-date
            account information and understand the consequences of providing
            false information.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(7)}>
            8. Third Party Applications
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[7] && (
            <div class="toggle-content">
          <p className="mb-10">
            We integrate with many third party applications (hereinafter "Third
            Party Application(s)"). Access and use of the Third Party
            Applications may require acceptance of terms of service and privacy
            policies applicable to such Third Party Applications (hereinafter
            "Third Party Terms"). You are responsible for reading and
            understanding the Third Party Terms before accessing or using any
            Third Party Application. You acknowledge and agree that Tera
            Connects platform is not liable for any Third Party Applications.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(8)}>
            9. Indemnity
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[8] && (
            <div class="toggle-content">
          <p className="mb-10">
            Tera Connects platform is not liable to indemnify any user for any
            financial loss suffered by the said user by using the services on
            the Platform.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(9)}>
            10. Intellectual property
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[9] && (
            <div class="toggle-content">
          <p className="mb-10">
            The content on our website and through our services, including text,
            graphics, logos, and images, is the property of the Company and is
            protected by copyright laws. You may not reproduce, modify,
            distribute, or use any of the content without our prior written
            consent.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(10)}>
            11. Feedback and submission
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[10] && (
            <div class="toggle-content">
          <p className="mb-10">
            By submitting feedback or content, you confirm ownership and grant
            us a license to use it.{" "}
          </p>
          <p>
            We respect your confidentiality but reserve the right to use and
            disclose submitted content.{" "}
          </p>
          <p>
            We have no obligation to use, review or respond to any feedback or
            content, and you indemnify us from any claims.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(11)}>
            12. Blacklisting of Organization/Individual
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[11] && (
            <div class="toggle-content">
          <p className="mb-10">
            At Tera Connects platform, we are committed to protecting the
            privacy of our users and clients. This privacy policy outlines how
            we handle and safeguard the information we collect from users and
            clients.{" "}
          </p>
          <p>
            We understand that some organizations/Individuals may not align with
            our values and mission, and as such, we may choose to blacklist such
            organizations. Blacklisting means that we will not provide any
            services or products to these organizations/individuals, and their
            information will not be stored in our databases.{" "}
          </p>
          <p>
            The criteria for blacklisting an organization/individual may
            include, but are not limited to:{" "}
          </p>
          <ul className="circle-list">
            <li>Engaging in illegal activities</li>
            <li>Engaging in unethical practices </li>
            <li>Promoting hate speech, discrimination, or intolerance </li>
            <li>Contributing to environmental harm </li>
            <li>
              Engaging in activities that harm the safety and well-being of
              individuals{" "}
            </li>
          </ul>
          <p>
            If we choose to blacklist an organization/individual, we may also
            share their information with relevant authorities, in accordance
            with applicable laws and regulations.
          </p>
          <p>
            We take appropriate measures to safeguard the information we collect
            from users and clients, including the information of blacklisted
            organizations/individuals. This includes implementing appropriate
            technical and organizational measures to prevent unauthorized
            access, use, or disclosure of information.{" "}
          </p>
          <p>
            By using our services or products, users and clients acknowledge and
            agree to this privacy policy, including our blacklisting policy. We
            may update this policy from time to time, and any updates will be
            posted on our website.{" "}
          </p>
          <p>
            You agree that from time to time we may remove the service for
            indefinite periods of time or cancel the service at any time,
            without notice to you.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(12)}>
            13. Errors,Omissions and inaccuracies
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[12] && (
            <div class="toggle-content">
          <p className="mb-10">
            On rare occasions, information on our website or in the Service may
            have clerical or other mistakes, inaccuracies, or omissions. We
            retain the right, at any time and without prior notice (including
            after you have made your service request), to rectify any mistakes,
            inaccuracies, or omissions, and to modify or update information or
            cancel services if any information in the Service or on any
            associated website is erroneous.{" "}
          </p>
          <p>
            We undertake no obligation to update, amend or clarify information
            in the Service or on any related website, including without
            limitation, pricing information, except as required by law. No
            specified update or refresh date applied in the Service or on any
            related website, should be taken to indicate that all information in
            the Service or on any related website has been modified or updated.
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(13)}>
            14. Disclaimer of warranties and limitations
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[13] && (
            <div class="toggle-content">
          <p className="mb-10">
            We make no guarantees, representations, or warranties that your use
            of our service will be continuous, timely, secure, or error-free.We
            do not warrant that the results gained from using the service will
            be accurate or dependable.{" "}
          </p>
          <p>
            You expressly accept that whether you can or cannot use the service,
            you do so at your own risk. Unless expressly stated otherwise, the
            service and all products and services delivered to you via the
            service are provided "as is" and "as available" for your use,
            without any express or implied representation, warranty, or
            condition of any kind, including without limitation any implied
            warranty or condition of title, non-infringement, merchantability,
            or fitness for a particular purpose.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(14)}>
            15. Severability
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[14] && (
            <div class="toggle-content">
          <p className="mb-10">
            The unenforceable portion of any term of these Terms of Service is
            presumed severed from these Terms of Service, and the other sections
            are legal and enforceable to the maximum extent permitted by
            applicable law. If any provision of these Terms of Service is
            determined to be invalid, void, or unenforceable, the legality and
            enforceability of the other sections shall not be affected.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(15)}>
          16. Entire Agreement
          <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[15] && (
            <div class="toggle-content">
            <p className="mb-10">
            These Terms comprise the complete agreement between the User and
            Tera Connects with regard to this Site and replace all former or
            contemporaneous discussions with respect to this Site, whether
            electronic, oral, or written. A printed version of these Terms, as
            well as any notice given in electronic form, will be admissible in
            judicial or administrative proceedings based on or relating to these
            Terms to the same extent and under the same conditions as other
            business documents and records generated and maintained in printed
            form. Any rights not expressly granted in this document are
            reserved.
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(16)}>
          17. Termination
          <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[16] && (
            <div class="toggle-content">
            <p className="mb-10">
            The obligations and liabilities of the parties incurred prior to the
            termination date shall survive the termination of this agreement for
            all purposes.{" "}
          </p>
          <p>
            These Terms of Service are in force until either you or us terminate
            them. You may terminate these Terms of Service at any time by
            telling us that you no longer desire to utilize our Services or by
            discontinuing usage of our website.
          </p>
          <p>
            {" "}
            If, in our sole discretion, you fail to comply with any term or
            provision of these Terms of Service, or we suspect that you have
            failed to comply, we may terminate this agreement at any time
            without notice, and you will remain liable for all amounts due up to
            and including the date of termination; and/or we may deny you access
            to our Services. (or any part thereof).{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(17)}>
          18. Delete Account
          <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[17] && (
            <div class="toggle-content">
            <p className="mb-10">
            {" "}
            When you delete an account from our application,all of your data is
            permanently gone.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(18)}>
          19. Changes to Terms of Service
           <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[18] && (
            <div class="toggle-content">
          <p className="mb-10">
            {" "}
            You can review the most current version of the Terms of Service at
            any time at this page. We reserve the right, at our sole discretion,
            to update, change or replace any part of these Terms of Service by
            posting updates and changes to our website. It is your
            responsibility to check our website periodically for changes. Your
            continued use of or access to our website or the Service following
            the posting of any changes to these Terms of Service constitutes
            acceptance of those changes.{" "}
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(19)}>
          20. Contact information
          <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[19] && (
            <div class="toggle-content">
          <p className="mb-10">
            If you have any questions or concerns regarding this Agreement,
            please contact us at contactus@teraconnects.com
          </p>
          </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(20)}>
          21. Last updated
          <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[20] && (
            <div class="toggle-content">
          <p>This Terms and Conditions was last updated on 01 January 2024.</p>
          </div>)}
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;

