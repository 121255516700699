import React from "react";
import { Helmet } from "react-helmet";
import "./privacy.css";
import { useState } from "react";

function PrivacyPolicy() {
  const [showContent, setshowContent] = useState(new Array(18).fill(false));

  // Function to toggle content visibility for a specific section
  const toggleSection = (index) => {
    setshowContent((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <div className="container tnC-page">
      <Helmet>
        <title>Privacy Policy </title>
        <meta
          name="description"
          content="Our Privacy Policy explains how we collect, use, and protect your personal information. Please review it carefully to understand our practices."
        />
      </Helmet>
      <div className="p-6 bg-gray-100 rounded-lg ">
        <div className="home-hero-section privacy-page">
          <div className="container lg:flex head-home-sec gap-extra">
            <div className="lg:w-6/12  head-home-sec-centre">
              <h3 className="heading">
                {" "}
                <b>Privacy Policy</b>
              </h3>
            </div>
            <div className="lg:w-6/12 head-home-sec-centre">

            </div>
          </div>
        </div>
        <div className="pvcpolcy">
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(0)}>
            1. Overview
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[0] && (
            <div class="toggle-content">
              <p className="mb-10">
                Tera Connects and our affiliates and subsidiaries ("Tera", "we",
                "our", and "us") understand the significance of safeguarding
                personal information. This privacy policy ("Privacy Policy")
                outlines and assists you in understanding how Tera collects, uses,
                and discloses personal information in connection with providing you
                with access to and use of our website - https://teraconnects.com
                ("Website"). The Tera, Terms and Conditions (the "Terms") include
                this Privacy Policy by reference.{" "}
              </p>
              <ul className=" circle-list">
                <li>collect</li>
                <li>use</li>
                <li>store</li>
                <li>transfer, and</li>
                <li>disclose</li>
              </ul>
              <p className="capitalize">
                information we obtain through the Site. All other terms that are not
                defined here will have the meanings specified in the Terms.{" "}
              </p>
              <p>
                By accessing or using our Website, you agree to be legally bound by
                this Privacy Policy. You agree that we may collect, use, share, and
                disclose your Personal Information in the ways indicated in this
                Privacy Policy. If you use the Services on behalf of another person
                (including, but not limited to, a minor or a natural person), you
                represent that you are authorized by that person to (i) accept this
                Privacy Policy on their behalf, and (ii) consent to our collection,
                use, and disclosure of such person's Personal Information as
                described in this Privacy Policy.{" "}
              </p>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(1)}>
            2. Your Privacy is Our Priority
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[1] && (
            <div class="toggle-content">
              <p className="mb-10">
                Our mission is to build world class human resource systems and offer
                a range of healthcare services for organizations. Our dedication to
                being transparent about the data we gather about you, how it is
                used, and with whom it is shared is central to this purpose.{" "}
              </p>
              <p className="mb-10">
                This Privacy Statement applies whenever you use our Services. As
                detailed in this Privacy Policy, Cookie Policy, Settings, and our
                Help Center, we provide our users with options about the data we
                gather, use, and share.{" "}
              </p>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(2)}>
            3. Consent
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[2] && (
            <div class="toggle-content">
              <p className="mb-10">
                This Privacy Policy ("Policy") describes the policies and procedures
                governing the collection, use, disclosure, and protection of your
                information when you use Tera Platforms.in or the Tera Connects
                mobile application (collectively, "Tera Connects Platform") made
                available by Morse Team ("Tera Connects", "Company", "we", "us" and
                "our"), a private company established under the laws of India with
                its registered office in Hyderabad.{" "}
              </p>
              <p>
                The words "you" and "your" apply to the Tera Connects Platform user.
                The word "Services" applies to any services provided by Tera
                Connects, whether on or off the Tera Connects Platform.Please read
                this Policy before using the Tera Connects Platform or providing
                Tera Platforms with any confidential information. This Policy is an
                extension of, and should be reviewed in conjunction with, the Terms
                of Service.{" "}
              </p>
              <p>
                You agree and assent to the gathering, transfer, use, storage,
                disclosure, and sharing of your information as outlined and gathered
                by us in compliance with this Policy by using the Tera Connects
                Platform and the Services. Please do not use or access the Tera
                Connects Platform if you do not concur with the Policy.{" "}
              </p>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(3)}>
            4. Changes to our Privacy Policy
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[3] && (
            <div class="toggle-content">
              <p className="mb-10">
                We may alter our Policy from time to time, and any changes will be
                noted on this page. If we make any major modifications to this
                Policy, we will make every effort to provide you with reasonable
                notice of such changes, such as by conspicuous notice on the Tera
                Connects Platform or to your email address on record, and we will
                get your permission as needed by applicable law. To the extent
                permitted by applicable law, your continuing use of our Services
                following the publication or transmission of a notice concerning
                changes to this Policy shall indicate your approval to the amended
                Policy.{" "}
              </p>
              <p>
                We reserve the right to amend this Privacy Policy as we see fit or
                as required by law. Any modifications will be posted on Our Site
                immediately, and you will be assumed to have accepted the terms of
                the Privacy Policy upon your first use of Our Platform following the
                changes. We urge that you visit this page on a frequent basis to
                stay up to date.
              </p>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(4)}>
            5. Links to Other websites
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[4] && (
            <div class="toggle-content">
              <p className="mb-10">
                The Tera Connects Platform may contain links to other websites.This
                Policy does not apply to any personal information about you gathered
                while browsing such websites. Tera Platforms is not liable for, and
                has no control over, the practices and content of any website viewed
                through the Tera Connects Platform's connections. Furthermore, the
                Company is not liable for any communication received by you from any
                connected website.
              </p>
              <p>
                The Tera Connects Platform is not liable for any mistakes,
                omissions, or statements on any of its pages, links, or connected
                websites. The Tera Connects Platform in no way endorses any
                advertising on its pages or on any connected website.You are
                requested to verify the accuracy of all information on your own
                before undertaking any reliance on such information.{" "}
              </p>
              <p>
                This Policy shall not apply to any information you may disclose to
                any of our service providers/service personnel which we do not
                require you to disclose to us or any of our service providers under
                this Policy.{" "}
              </p>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(5)}>
            6. Information we collect from You
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[5] && (
            <div class="toggle-content">
              <p className="mb-10">
                We collect Personal Information when you choose to provide it to us
                including when you: register to use the Platform, use the Platform
                and communicate with us.{" "}
              </p>
              <ul className="circle-list">
                <li>
                  <strong className="mb-10 mt-20 circle-list">
                    6.1. Information you give us
                  </strong>
                  <p className="mb-10 mt-10">
                    This includes information submitted when you:
                  </p>
                  <ul className="circle-list ">
                    <li>
                      Signup as an applicant on the Tera Connects Platform, you will
                      be asked to provide information such as your name, contact
                      details (including email address and mobile number), work
                      experience, educational qualifications, data relating to your
                      current and previous remuneration or salary, a copy of your
                      resume,date of birth, profile picture, payment or banking
                      information, and so on.
                    </li>
                    <li>
                      If you sign up to use our Services as a client, you will be
                      asked to provide location details,your name, email address,
                      state,city, contact number, and{" "}
                    </li>
                    <li>
                      Provide content to us, which may include reviews, service
                      details and history, contact information of people you refer
                      to us and other information you provide on the Tera Platforms
                      Platform (“Your Content”).{" "}
                    </li>
                    <li>Correspond with Tera Connects for customer support</li>
                    <li>
                      Enable features that require Tera Connects's access to your
                      address book or calendar{" "}
                    </li>
                    <li>Report problems for troubleshooting</li>
                  </ul>
                </li>
                <li>
                  <strong className="mb-10 mt-20">
                    6.2. Information we collect about you{" "}
                  </strong>
                  <p className="mb-10 mt-10">
                    We will automatically collect and analyze the following
                    demographic and other information throughout each of your visits
                    to the Tera Connects Platform.{" "}
                  </p>
                  <ul className="circle-list mb-10">
                    <li>
                      When you communicate with us (via email, phone, through the
                      Tera Connects Platform or otherwise), we may maintain a record
                      of your communication
                    </li>
                    <li>
                      When you interact with our site/platform ,technical
                      information such as your IP address, operating system,browser
                      type, version and device type{" "}
                    </li>
                    <li>
                      Using our Services, we may collect and store information about
                      you to process your requests and automatically complete forms
                      for future transactions, including (but not limited to) your
                      phone number, address, email, billing information and credit
                      or payment card information.
                    </li>
                    <li>
                      Usage information such as the pages you visit and the features
                      you use on our platform{" "}
                    </li>
                    <li>
                      The Tera Connects app may access metadata and other
                      information associated with files, such as photos and
                      contacts, on your mobile device to facilitate social
                      interactions and for other purposes described in our policy.
                      This includes collecting calendar information such as event
                      details and attendees.
                    </li>
                    <li>
                      List of URLs starting with a referring site, your activity on
                      Our Site, and the site you exit to will be automatically
                      collected.{" "}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(6)}>
            7. Third party sites and social media platforms
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[6] && (
            <div class="toggle-content">
              <p className="mb-10">
                Other websites may reference or link to our Website, and our Website
                may mention or link to other websites. These other domains and
                websites are not under our control. We advise our Users to review
                the privacy policies of each website and application with which they
                interact. We do not sponsor, screen, or authorize such other
                websites or applications, and we are not responsible for their
                privacy practices or content. You visit these other websites or
                applications at your own risk, and we are not responsible for how
                such websites or applications utilize your information.{" "}
              </p>
              <p>
                The Website also includes links and interactive elements to many
                social media networks. If you already use these platforms, cookies
                from these platforms may be placed on your device when you visit our
                Website. You should be aware that any Personal Information you
                freely include and send online in a publicly available blog, chat
                room, social media platform, or other online location, or that you
                discuss in an open forum, may be read and used by others without
                limitation. We are unable to control how your information is used
                while connecting with a social media platform, and by using such
                services, you accept the risk that the Personal Information you
                supply may be accessed and used by third parties for a variety of
                purposes.
              </p>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(7)}>
            8. Cookies
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[7] && (
            <div class="toggle-content">
              <p>
                Our Site may place and access certain first party Cookies on your
                computer or device. First party Cookies are those placed directly by
                Us and are used only by Us. We use Cookies to facilitate and improve
                your experience of Our Site and to provide and improve Our products
                and services. We have carefully chosen these Cookies and have taken
                steps to ensure that your privacy is protected and respected at all
                times.{" "}
              </p>
              <p>
                Third Party entities may use cookies, web beacons, SDKs and other
                technologies to identify your device when you visit the Tera
                Connects Platform and use our Services, as well as when you visit
                other online sites and services.{" "}
              </p>
              <p>
                Please read our Cookie Policy for additional information on the use
                of cookies and other technologies indicated in this area, as well as
                your options with regard to such technologies.
              </p>
              <ul className="circle-list mb-10 ">
                <li>
                  <strong>Cookie Policy</strong>
                  <p>
                    This Cookie Policy ("Cookie Policy") is part of and incorporated
                    into, and should be read in conjunction with, the Privacy Policy
                    ("Policy") and Terms of Service. It helps to inform about how
                    your(“User”) personal data is collected and processed by a
                    website or application, and gives you the opportunity to opt-out
                    or adjust your preferences.
                  </p>
                </li>
                <li>
                  <strong>What are cookies and local storage?</strong>
                  <p>
                    Cookies and local storage are two technologies available to web
                    developers for storing data in a user's browser.{" "}
                  </p>
                  <p>
                    Cookies are little text files that are placed on your computer,
                    phone, or other device when you visit websites. They are used to
                    record information about your activity on the Platform, such as
                    the pages you see, the content you listen to, the adverts you
                    click on, your preferences, and other actions you take.Cookies
                    might be "persistent" or "session." Session cookies are removed
                    when you shut your web browser, but persistent cookies remain on
                    your device for a defined amount of time or until you delete
                    them.{" "}
                  </p>
                  <p>
                    Local storage is a technique that allows a website or
                    application to store data on your device locally. "Software
                    Development Kits" (also known as SDKs) work similarly to pixels
                    and cookies, but in the context of a mobile app, where pixels
                    and cookies cannot always function. The primary app developer
                    can install code (the SDK) from partners in the app, allowing
                    the partner to gather information about user engagement with the
                    app, as well as information about the user device and network
                    information.{" "}
                  </p>
                </li>
                <li>
                  <strong>We employ these technologies because... </strong>
                  <p>
                    We use cookies and other identification technologies for a
                    variety of purposes, including authenticating users, storing
                    information about you and your use of our Services and the Tera
                    Connects Platform (including on your device or in your browser
                    cache), remembering user preferences and settings, determining
                    the popularity of content, and generally understanding the
                    online behaviors and interests of people who interact with our
                    Services.{" "}
                  </p>
                </li>
                <li>
                  <strong>We use cookies as…</strong>
                  <p>
                    Authentication: To remember login information so that you don't
                    have to enter your credentials every time you visit a
                    website/platform. Personalization: Used to personalize content
                    based on your interests, browsing history, and other
                    preferences. Analytics: Used to track your behavior on a
                    website, such as which pages you visit and how long you stay on
                    each page. This data can be used to improve the website's design
                    and content. Advertising: To track you across multiple websites
                    and deliver targeted advertising based on your interests and
                    browsing history.{" "}
                  </p>
                  <p>
                    Note: All this happens with one User ID where data is not stored
                    in your phone or computer. We store them safely in our cloud.
                  </p>
                </li>
                <li>
                  <strong>Third Party Cookies:</strong>
                  <p>
                    When you visit the Tera Connects Platform, one of the many
                    vendors/service providers we work with may also place cookies on
                    your device in order for them to provide the services they are
                    offering.You could get cookies when you access the Tera Connects
                    Platform from other websites or domains. The website of the
                    relevant third party may have further details about these
                    cookies.
                  </p>
                </li>
              </ul>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(8)}>
            9. How can I control Cookies?
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[8] && (
            <div class="toggle-content">
              <p className="mb-10">
                Most web browsers are originally configured to accept cookies
                automatically. You may configure your browser to prohibit cookies or
                to notify you when cookies are transmitted to your device. Cookies
                may be managed in a variety of ways. To learn more about how to edit
                or modify your browser settings, please see your browser's
                instructions or help screen.{" "}
              </p>
              <p>
                If you disable the cookies that we use, your experience on the Tera
                Connects Platform may be impacted. For example, you may be unable to
                visit certain areas of the Tera Connects Platform, or you may not
                receive personalized information when you visit the Tera Connects
                Platform, or you may be unable to login to services or programs,
                such as forums or accounts.{" "}
              </p>
              <p>
                If you use several devices to see and access the Tera Connects
                Platform (for example, your computer, smartphone, tablet, etc.), you
                must ensure that each browser on each device is configured to your
                cookie preferences.{" "}
              </p>
              <p>
                Changing your Cookie Preferences. The browser settings for modifying
                your cookies settings are normally accessible in your internet
                browser's 'options' or 'preferences' menu. The following websites
                may be useful in understanding these settings. Otherwise, utilize
                your internet browser's 'Help' function for further information.
              </p>
              <ul className="circle-list">
                <li>
                  <a href="#" target="_blank" rel="noreferrer">
                    Internet Explorer
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank" rel="noreferrer">
                    Chrome
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank" rel="noreferrer">
                    Firefox
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank" rel="noreferrer">
                    Safari
                  </a>
                </li>
                <li>
                  For any other browser, please directly consult the cookie
                  management help information available on the Internet.
                </li>
              </ul>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(9)}>
            10. Uses of your information
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[9] && (
            <div class="toggle-content">
              <ul className="circle-list">
                <li>
                  To provide our services: We may use your information to provide
                  you with the services you requested or to fulfill our contractual
                  obligations. For example, if you opt for service from us, we will
                  use your information to process your request.
                </li>
                <li>
                  To improve our services: We may use your information to understand
                  how our services are used and to improve them. This may include
                  analyzing data on how our website is accessed or how our services
                  are used. We may also use your feedback to improve our services.{" "}
                </li>
                <li>
                  To communicate with you: We may use your information to
                  communicate with you about our services, promotions, or other
                  topics that may be of interest to you. This may include sending
                  you marketing communications or other updates about our services.{" "}
                </li>
                <li>
                  To personalize your experience: We may use your information to
                  personalize your experience on our platform or to tailor our
                  services to your preferences. This may include showing you
                  personalized content or recommendations based on your browsing
                  history or other information we have collected about you.{" "}
                </li>
                <li>
                  To comply with legal obligations: We may use your information to
                  comply with applicable laws, regulations, or legal processes. This
                  may include responding to requests from law enforcement agencies
                  or government officials.{" "}
                </li>
                <li>
                  To protect our rights and property: We may use your information to
                  protect our rights and property or the rights and property of our
                  users or third parties. This may include enforcing our policies or
                  terms of service, investigating or preventing fraud, or responding
                  to claims or legal actions.{" "}
                </li>
              </ul>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(10)}>
            11. Disclosure and distribution of your information{" "}
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[10] && (
            <div class="toggle-content">
              <ul className="circle-list">
                <li>
                  With your consent: We may share your information with third
                  parties if you have given us explicit consent to do so. For
                  example, if you sign up for a newsletter or participate in a
                  survey, we may share your contact information with our partners
                  who provide these services.{" "}
                </li>
                <li>
                  Legal compliance: We may disclose and distribute your information
                  if we believe that it is necessary to comply with applicable laws,
                  regulations, legal processes or governmental requests. For
                  example, we may disclose your information to law enforcement
                  agencies if we receive a valid subpoena or court order.{" "}
                </li>
                <li>
                  Service providers: We may disclose and distribute your information
                  to our service providers who provide services on our behalf, such
                  as payment processing, data analysis, email delivery, hosting
                  services, and customer service. These service providers are
                  contractually obligated to use your information only for the
                  purpose of providing the services we have requested and to keep
                  your information confidential.
                </li>
                <li>
                  Business transfers: In the event that we sell or transfer all or a
                  portion of our business or assets, we may disclose and distribute
                  your information to the acquiring party or parties. If such a
                  transfer occurs, we will use reasonable efforts to ensure that the
                  acquiring party agrees to comply with this Privacy Policy.{" "}
                </li>
                <li>
                  Aggregated or de-identified information: We may disclose and
                  distribute aggregated or de-identified information about our
                  users, which does not identify any individual, to third parties
                  for marketing, advertising, research, or other purposes.{" "}
                </li>
              </ul>
              <p>
                We will not disclose or distribute your information for any other
                purpose without your explicit consent, unless required by law or to
                protect our legal rights.
              </p>
              <p>
                We take appropriate measures to ensure that any third parties who
                receive your information in accordance with this Privacy Policy are
                bound by appropriate confidentiality and security obligations.{" "}
              </p>
              <p>
                Please note that we may use third-party advertising companies to
                serve ads when you visit our website. These companies may use
                information about your visits to this and other websites in order to
                provide advertisements about goods and services of interest to you.
                If you would like more information about this practice and to know
                your choices about not having this information used by these
                companies, please see our Cookie Policy.
              </p>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(11)}>
            12. Data security precautions
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[11] && (
            <div class="toggle-content">
              <p>
                We have put in place the necessary technological and security
                safeguards to protect the data we've gathered.
              </p>
              <p>
                To safeguard the sensitive personal information you supply, we
                employ vault and tokenization services supplied by third-party
                service providers. Third-party service providers for our vault and
                tokenization services, as well as our payment gateway and payment
                processing, adhere to payment card industry standards. (generally
                referred to as PCI compliant service providers). It is not
                recommended that you submit your entire credit/debit card
                information through unencrypted electronic networks. It is your
                responsibility to keep these facts private. We request that you not
                share your password with anybody.{" "}
              </p>
              <p>
                Please be advised that information transfer via the internet is not
                totally secure. We will try our utmost to secure your personal
                information, but we cannot guarantee the security of data
                transferred over the Tera Connects Platform. We shall employ
                rigorous physical, technological, and administrative precautions to
                try to prevent unauthorized access to your information after we have
                received it.{" "}
              </p>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(12)}>
            13. Phishing/Exploiting
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[12] && (
            <div class="toggle-content">
              <p>
                Platform is quite concerned about identity theft and the practice
                known as "phishing." Data security is a primary priority in order to
                safeguard You against identity theft. We do not and will never ask
                for your credit card information or national identification numbers
                in an unsecure or unsolicited e-mail or phone call.{" "}
              </p>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(13)}>
            14. Opt out
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[13] && (
            <div class="toggle-content">
              <p>
                You may stop or restrict the placement of cookies on your computer
                or remove them from your browser by adjusting your web browser
                preferences. Please note that cookie-based opt-outs are not
                effective on mobile applications. However, on many mobile devices,
                application Users may opt out of certain mobile ads via their device
                settings.{" "}
              </p>
              <p>
                {" "}
                Please write to us if you desire to revoke your permission for the
                use and disclosure of your personal information in the way specified
                in this Policy through email. Please be aware that it might take
                some time for us to handle your request, and that it must go into
                effect no later than 5 (Five) working days after we receive it.
                After that point, we won't process your personal data unless it's
                necessary for us to do so in order to meet our legal obligations. In
                the event that you revoke your permission, we might not be able to
                provide you with any or all Services.{" "}
              </p>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(14)}>
            15. Summary of Your Rights under GDPR
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[14] && (
            <div class="toggle-content">
              <p>Under GDPR you have </p>
              <ul className="circle-list">
                <li>
                  The right to be informed about how your personal data is being
                  used.
                </li>
                <li>The right to access your personal data. </li>
                <li>
                  The right to have your personal data corrected if it is
                  inaccurate.{" "}
                </li>
                <li>The right to have your personal data erased. </li>
                <li>
                  The right to restrict the processing of your personal data.{" "}
                </li>
                <li>The right to data portability. </li>
                <li>
                  The right to object to the processing of your personal data.
                </li>
                <li>
                  The right not to be subject to automated decision-making and
                  profiling{" "}
                </li>
                <li>
                  We currently make the following automated decisions: To send
                  newsletters and internal campaign emails{" "}
                </li>
                <li>
                  We currently profile your personal data for the following
                  purposes: To send newsletters and internal campaign emails{" "}
                </li>
              </ul>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(15)}>
            16. Children’s Privacy
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[15] && (
            <div class="toggle-content">
              <ul className="circle-list">
                <li>
                  We prioritize the privacy and protection of children's personal
                  information and adhere to all applicable laws and regulations
                  regarding children's privacy, including the Children's Online
                  Privacy Protection Act (COPPA).{" "}
                </li>
                <li>
                  We do not knowingly collect any personally identifiable
                  information from children under the age of 18 without obtaining
                  verifiable parental consent.{" "}
                </li>
                <li>
                  If we become aware that a child has provided us with their
                  personal information without parental consent, we will promptly
                  delete such information from our records.{" "}
                </li>
                <li>
                  We do not share any personal information of children with third
                  parties without explicit consent from parents or legal guardians.{" "}
                </li>
                <li>
                  Parents or legal guardians have the right to review, modify, or
                  delete any personal information of their children that we have
                  collected and stored, by contacting us through the provided
                  channels in our privacy policy.{" "}
                </li>
              </ul>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(16)}>
            17. Web Beacons
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[16] && (
            <div class="toggle-content">
              <ul className="circle-list">
                <li>
                  We may use web beacons, also known as pixel tags or clear GIFs, on
                  our website to collect information about your interactions with
                  our site, such as the pages you visit and the links you click.{" "}
                </li>
                <li>
                  Tracking and Analytics: We may use web beacons for tracking and
                  analytics purposes to gather data about how our website is used
                  and to improve the user experience. This may include collecting
                  information such as your IP address, browser type, and operating
                  system.{" "}
                </li>
                <li>
                  Opt-Out Options: You can usually disable web beacons by adjusting
                  your browser settings or by opting out of our tracking and
                  analytics services. However, please note that disabling web
                  beacons may affect the functionality of our website and your
                  ability to access certain features.{" "}
                </li>
              </ul>
            </div>)}
          <h4 className="mt-8 font-semibold" onClick={() => toggleSection(17)}>
            18. Last updated
            <span className="toggle-arrow"><i class="fa-solid fa-angle-down"></i></span>
          </h4>
          {showContent[17] && (
            <div class="toggle-content">
              <p>This Terms and Conditions was last updated on 01 January 2024.</p>
            </div>)}
        </div>

      </div>
    </div>
  );
}

export default PrivacyPolicy;