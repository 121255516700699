import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function getCurrencySymbol(currency) {
  switch (currency) {
    case 'USD':
      return '$';
    case 'INR':
      return '₹';
    default:
      return currency; // Return the currency code if not recognized
  }
}

function RangeFilter() {
  const [selectedValue, setSelectedValue] = useState(50); // Default value
  const [currency, setCurrency] = useState('USD'); // Default currency
  const [price, setPrice] = useState(selectedValue * 10); // Initial price calculation

  const handleRangeChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setSelectedValue(newValue);
    setPrice(newValue * (currency === 'USD' ? 10 : 820)); // Update the price based on the selected value and currency
  };

  const handleCurrencyChange = (event) => {
    const newCurrency = event.target.value;
    setCurrency(newCurrency);
    setPrice(selectedValue * (newCurrency === 'USD' ? 10 : 820)); // Update the price based on the selected currency
  };
  const { t } = useTranslation();

  return (
    <div>
      <form className='price-form-premium'>
        <label>
        {t('pricingform.SelectMembers')}
          <input
            type="range"
            min="1"
            max="50"
            value={selectedValue}
            onChange={handleRangeChange}
          />
        </label>
      </form>

      <div className='selected-member-value'>
        <h5>{selectedValue}</h5>
        <p>/{t('pricingform.Members')}</p>
      </div>

      <div className='currency-switcher'>
        <label>
          <select value={currency} onChange={handleCurrencyChange}>
            <option value="USD">USD</option>
            <option value="INR">INR</option>
          </select>
        </label>
      </div>

      <div className='selected-member-value selected-member-price'>
        <h5>{getCurrencySymbol(currency)} {price}</h5>
        <p>/{t('pricingform.Yearly')}</p>
      </div>
    </div>
  );
}

export default RangeFilter;
